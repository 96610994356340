<template>
    <main>
        <!-- Modal -->
        <modalUrgenciaEvolucion :evoluciones="evoluciones" ></modalUrgenciaEvolucion>              
        <div class="row mt-2">
            
                    <div class="col-12 d-flex justify-content-end">
                        
                        <button @click="historialEvoluciones();" type="button" class="btn btn-outline-primary"><i class="fas fa-eye"></i>&nbsp;Ver Evoluciones</button>
                    </div>
            
            <div class="form-group col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">Fecha</label>
                <input type="date" class="form-control form-control-sm" v-model="fecha" :class="{'is-invalid':$v.fecha.$error && $v.fecha.$dirty}">
            </div>
            <div class="form-group col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">Hora</label>
                <input type="time" class="form-control form-control-sm" v-model="hora" :class="{'is-invalid':$v.hora.$error && $v.hora.$dirty}">
            </div>
            <div class="form-group col-lg-12 col-md-12 col-sm-12">
                <label class="form-label">Subjetivo</label>
                <textarea cols="30" rows="3" class="form-control" v-model="subjetivo" :class="{'is-invalid':$v.subjetivo.$error && $v.subjetivo.$dirty}"></textarea>
            </div>
            <div class="form-group col-lg-12 col-md-12 col-sm-12">
                <label class="form-label">Objetivo</label>
                <textarea cols="30" rows="3" class="form-control" v-model="objetivo" :class="{'is-invalid':$v.objetivo.$error && $v.objetivo.$dirty}"></textarea>
            </div>
            <div class="form-group col-lg-12 col-md-12 col-sm-12">
                <label class="form-label">Analisis</label>
                <textarea cols="30" rows="3" class="form-control" v-model="analisis" :class="{'is-invalid':$v.analisis.$error && $v.analisis.$dirty}"></textarea>
            </div>
            <div class="form-group col-lg-12 col-md-12 col-sm-12">
                <label class="form-label">Plan</label>
                <textarea cols="30" rows="3" class="form-control" v-model="plan" :class="{'is-invalid':$v.plan.$error && $v.plan.$dirty}"></textarea>
            </div>
            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                <div class="form-group row">
                    <label  class="col-sm-6 col-form-label"><i class="fa fa-stethoscope"></i> P.A.SISTOL / P.A.DIASTOL <span class="text-danger">(mmHg)</span> </label>
                    <div class="col-sm-6 ">
                        <div class="row">
                            <div class="col-sm ">
                                <input type="number" v-model="pa_sistolica" class="form-control" :class="{'is-invalid':$v.pa_sistolica.$error && $v.pa_sistolica.$dirty}">
                            </div>
                            <div class="col-sm-1 ">
                                <h1>/</h1>
                            </div>
                            <div class="col-sm ">
                                <input type="number" v-model="pa_diastolica" class="form-control" :class="{'is-invalid':$v.pa_diastolica.$error && $v.pa_diastolica.$dirty}">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label  class="col-sm-6 col-form-label"><i class="fa fa-history"></i> PULSO <span class="text-danger">(lat/min)</span></label>
                    <div class="col-sm-6">
                        <input type="number" v-model="pulso" class="form-control" :class="{'is-invalid':$v.pulso.$error && $v.pulso.$dirty}">
                    </div>
                </div>

                <div class="form-group row">
                    <label  class="col-sm-6 col-form-label"><i class="fa fa-thermometer"></i> TEMPERATURA <span class="text-danger">(ºC)</span></label>
                    <div class="col-sm-6">
                        <input type="number" v-model="temperatura" class="form-control" :class="{'is-invalid':$v.temperatura.$error && $v.temperatura.$dirty}">
                    </div>
                </div>
                <div class="form-group row">
                    <label  class="col-sm-6 col-form-label"><i class="fa fa-lungs"></i> RESPIRACION <span class="text-danger">(r/m)</span></label>
                    <div class="col-sm-6">
                        <input type="number" v-model="respiracion" class="form-control" :class="{'is-invalid':$v.respiracion.$error && $v.respiracion.$dirty}" >
                    </div>
                </div>
                <div class="form-group row">
                    <label  class="col-sm-6 col-form-label"><i class="fa fa-weight"></i> PESO <span class="text-danger">(KG)</span></label>
                    <div class="col-sm-6">
                        <input type="number" v-model="peso" class="form-control" :class="{'is-invalid':$v.peso.$error && $v.peso.$dirty}">
                    </div>
                </div>
                <div class="form-group row">
                    <label  class="col-sm-6 col-form-label"><i class="fa fa-ruler-vertical"></i> TALLA <span class="text-danger">(CM)</span></label>
                    <div class="col-sm-6">
                        <input type="number" v-model="talla" class="form-control" :class="{'is-invalid':$v.talla.$error && $v.talla.$dirty}">
                    </div>
                </div>
                <div class="form-group row">
                    <label  class="col-sm-6 col-form-label"> SATURACION DE OXIGENO <span class="text-danger"></span></label>
                    <div class="col-sm-6">
                        <input type="number" v-model="saturacion_oxigeno" class="form-control" :class="{'is-invalid':$v.saturacion_oxigeno.$error && $v.saturacion_oxigeno.$dirty}">
                    </div>
                </div>
                <button type="button" class="btn btn-success" @click="guardar();">Guardar</button>
            </div>
        </div>
    </main>
</template>

<script>
import urgenciaEvolucionService from '../../../../services/urgenciaEvolucionService';
import Toast from '../../../../components/common/utilities/toast';
import $ from 'jquery';
import {required} from "vuelidate/lib/validators";
import modalUrgenciaEvolucion from './modalUrgenciaEvolucion';

export default {
    props:['idHistoria'],
    components:{modalUrgenciaEvolucion},
    data(){
        return {
            fecha:'',
            hora:'',
            subjetivo:'',
            plan : '',
            objetivo:'',
            analisis:'',
            peso:'',
            talla:'',
            pa_sistolica:'',
            pa_diastolica:'',
            respiracion:'',
            pulso:'',
            temperatura:'',
            saturacion_oxigeno:'',
            plan_conciencia:'',
            evoluciones:{}
        }
    },
    validations (){
        return {
            fecha:{required},
            hora:{required},
            subjetivo:{required},
            plan : {required},
            objetivo:{required},
            analisis:{required},
            peso:{required},
            talla:{required},
            pa_sistolica:{required},
            pa_diastolica:{required},
            respiracion:{required},
            pulso:{required},
            temperatura:{required},
            saturacion_oxigeno:{required},
        }
    },
    methods: {
        async guardar() {
            try {

                this.$v.$touch();

                if(this.$v.$invalid) return;

                let urgencia_evolucion ={
                    id_historia:this.idHistoria,
                    fecha:this.fecha,
                    hora:this.hora,
                    subjetivo:this.subjetivo,
                    plan : this.plan,
                    objetivo:this.objetivo,
                    analisis:this.analisis,
                    peso:this.peso,
                    talla:this.talla,
                    pa_sistolica:this.pa_sistolica,
                    pa_diastolica:this.pa_diastolica,
                    respiracion:this.respiracion,
                    pulso:this.pulso,
                    temperatura:this.temperatura,
                    saturacion_oxigeno:this.saturacion_oxigeno
                }
                this.LoaderSpinnerShow();
                await urgenciaEvolucionService.store(urgencia_evolucion);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });
                this.limpiaCampos();
            } catch (error) {
                this.LoaderSpinnerHide();
                 Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        limpiaCampos(){
            this.fecha='';
            this.hora='';
            this.subjetivo='';
            this.plan='';
            this.objetivo='';
            this.analisis='';
            this.peso='';
            this.talla='';
            this.pa_sistolica='';
            this.pa_diastolica='';
            this.respiracion='';
            this.pulso='';
            this.temperatura='';
            this.saturacion_oxigeno='';
        },
         async historialEvoluciones() {
            $('#modalEvolucion').modal('show');
            const response = await urgenciaEvolucionService.showByHc(this.idHistoria);
            this.evoluciones = response.data; 
        },
    },
}
</script>

<style scoped>

</style>
